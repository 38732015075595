import React from "react";
import { useLocalization, LocalizedLink as Link } from "gatsby-theme-i18n";

import Layout from "../components/Layout";
import HelpMenu from "../components/HelpMenu";
import QuestionEN4 from "../components/Questions/QuestionEN4";
import QuestionDE4 from "../components/Questions/QuestionDE4";
import QuestionFR4 from "../components/Questions/QuestionFR4";

export default function HelpPage() {
    const { locale } = useLocalization();

    return (
        <Layout>
            <main className="static help-wrap">               
                <div className="hamburger-help-wrap mobile-only">                      
                    <Link to="/help" className="hamburger-help"></Link>
                </div>
                <div className="help-menu mobile-hidden"><HelpMenu /></div>
                <div className="help-content">
                    { (locale == "en") && <QuestionEN4 /> }
                    { (locale == "de") && <QuestionDE4 /> }
                    { (locale == "fr") && <QuestionFR4 /> }
                </div>
            </main>
        </Layout>
    );
}
